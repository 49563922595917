import React from 'react';
import PropTypes from 'prop-types';
// import SanitizedHTML from 'react-sanitized-html';
import ReactMarkdown from "react-markdown";

import  "./assets/_index.scss";

// {/* <SanitizedHTML
//     allowedTags={['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
//     'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
//     'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre']}
//     html={sanitize(props.text)}
//     className="article-text"
// /> */}

const ArticleText = (props) => {
    return (
        <section className={`section section-article-text${props.sectionClass}`}>
            <ReactMarkdown source={props.text} className="article-text" escapeHtml={false} />
        </section>
    )
}

// Check all the prop types
ArticleText.propTypes = {
    sectionClass: PropTypes.string,
    text: PropTypes.string
};

// Specifies the default values for props:
ArticleText.defaultProps = {
    sectionClass: '',
    text: '<p>So, you’re sitting at your desk today and you’re thinking that you shouldn’t be. You are doing an amazing job, but you are underappreciated, not being offered the right type of training to progress and develop, or just flat out being underpaid.</p><p>The last few months here at Crux have really highlighted to us how some companies are paying really low numbers compared to others, but the people there have been there for so long that they don’t know any different! <a href="#">apply@cruxcareers.com</a></p><h2>Preparing Your CV</h2><p>So, you’re sitting at your desk today and you’re thinking that you shouldn’t be. You are doing an amazing job, but you are underappreciated</p><blockquote>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eget condimentum arcu, non tincidunt arcu. Ut ac fringilla diam, mattis finibus mi. Sed feugiat interdum eros.</blockquote>'
};

export default ArticleText;