import React, { useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import  "./assets/_index.scss";

import Breadcrumbs from "@components/breadcrumbs/Breadcrumb"

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const ContentHeader = (props) => {
    let contAnim = useRef(null);
    let titleAnim = useRef(null);
    let breadAnim = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            }
        });
        tl.to(contAnim, {
            duration: 0.4,
            css: {visibility: 'visible'}
        })
        .from(titleAnim, {
            opacity: 0,
            y: 100,
            delay: 0.3
        });
        if (!props.HideBreadcrumbs) {
            tl.from(breadAnim, {
                opacity: 0,
                delay: 0.3
            })
        }
    }, [props.HideBreadcrumbs])

    return (
        <div className={`section-content-header__content${props.sectionContainerClass}`} ref={el => contAnim = el}>
            <Row>
                <Col lg={12}>
                    {!props.HideBreadcrumbs &&
                    <div ref={el => breadAnim = el}>
                        <Breadcrumbs
                            className={props.breadcrumbsClass}
                            pageName={props.pageName}
                            pageName_1={props.pageName_1}
                            pageLink_1={props.pageLink_1}
                            lastClass_1={props.lastClass_1}
                            pageName_2={props.pageName_2}
                            pageLink_2={props.pageLink_2}
                            lastClass_2={props.lastClass_2}
                            pageName_3={props.pageName_3}
                            pageLink_3={props.pageLink_3}
                        />
                    </div>
                    }

                    <h1 className={`section-content-header__title h5${props.titleClass}`} ref={el => titleAnim = el}>
                        {props.Title}
                    </h1>
                </Col>
            </Row>
        </div>
    );
}

// Check all the prop types
ContentHeader.propTypes = {
    sectionClass: PropTypes.string,
    sectionContainerClass: PropTypes.string,
    HideBreadcrumbs: PropTypes.bool,
    breadcrumbsClass: PropTypes.string,
    pageName: PropTypes.string,
    pageName_1: PropTypes.string,
    pageLink_1: PropTypes.string,
    lastClass_1: PropTypes.string,
    pageName_2: PropTypes.string,
    pageLink_2: PropTypes.string,
    lastClass_2: PropTypes.string,
    Title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
};
  
// Specifies the default values for props:
ContentHeader.defaultProps = {
    sectionClass: '',
    sectionContainerClass: ' mt-33 mb-64 mt-md-40 mb-md-60 mt-xl-80 mb-xl-100',
    HideBreadcrumbs: false,
    breadcrumbsClass: ' section-breadcrumb',
    pageName: 'Home',
    pageName_1: '',
    pageLink_1: '',
    lastClass_1: '',
    pageName_2: '',
    pageLink_2: '',
    lastClass_2: '',
    Title: 'Our experienced team will provide the right career opportunities for you.',
    titleClass: ''
};

export default ContentHeader